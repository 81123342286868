/**
* Foundation for Sites by ZURB
* Version 6.3.0
* foundation.zurb.com
* Licensed under MIT Open Source
*/
body {
    font-family: 'Lato', sans-serif;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 307px, 313px, 407px, cover;
    background-position: 0% 0%, 100% 20%, 41% 100%;
    margin:0;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-start; /* align items in Main Axis */
    align-items: stretch; /* align items in Cross Axis */
    align-content: stretch; /* Extra space in Cross Axis */

    .main-content {
        background: rgba(0, 110, 182, 0.36);
        padding:155px 0 0;
        flex: 1 0 auto;
        height:calc(100vh - 100px); 
        }
    .footer {
        flex-shrink: 0;
    }

}

#wrap {
    width: 500px;
    margin: 0 auto;
    height: auto;
    padding: 0;
    position: absolute;
    top:180px;
    left:0;
    right:0;
    background:#fff;
    box-shadow:0px 3px 3px rgba(0, 0, 0, 0.3);
    h1 {
        background: transparent;
        color:#006eb6;
        margin:0;
        font-size:32px;
    }
    a {
        display:block;
        color:#fff;
        background:#006eb6;
        text-decoration: none;
        font-size: 24px;
        padding:8px 0 16px;
        text-align: center;
    }
    a:hover {
        background:#000;
    }
    ol {
        display: none;
    }
}
.home-link a {
    display: block;
    text-align: center;
    text-decoration: none;
    width: 100%;
    padding: 40px 0;
    color: #fff;
    font-size: 38px;
    background: #006eb6;
    position: absolute;
    right: 0;
}

@media (max-width: 600px) {
    .home-link a {
        font-size: 20px;
    }
}
.home-link:hover a{
    background-color: #abd0e9;
}

.error-title, h1{
    background: #006eb6;
    color: #fff;
    padding: 20px;
    text-align: center;
}

.error-title + h2 {
    margin: 0 0;
    color: #fff;
    background: #006eb6;
    padding: 20px;
    text-align: center;
    font-size: 60px;
    position: relative;
    bottom: 40px;
}

.footer .footer-links {
    position:relative;
    .nav.menu {
        box-shadow: none !important;
        margin: 0 !important;
        position: absolute;
        right:184px;
        top:16px;
        z-index: 0;
    }
}
